$mobile-size: 950px;

html {
    scroll-behavior: smooth;
    overflow-x: hidden;
}

body {
    background-color: #f2ede9;
    color: rgba(#212326, 1);
    font-family: "Ms Madi", cursive;
    font-size: 22pt;
    font-weight: 500;
    font-style: normal;
    overflow-x: hidden;
}

a {
    cursor: pointer;
}

button, .btn {
    
    &:hover {

    }
}

p::after{
    content: "\a";
    white-space: pre;
}