$mobile-size: 950px;

.home {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 40px;

    padding: 40px 10px;

    .branch {
        height: 300px;
        position: absolute;
        z-index: -1;
        opacity: 0.3;
    }

    .first-branch {
        top: -100px;
        left: 20px;
    }

    .second-branch {
        transform: rotate(180deg);
        bottom: -40px;
        right: 20px;
    }

    .logo {
        max-width: 150px;
    }

    .dontation {
        padding: 8px 30px;
        margin-top: -40px;
        background-color: rgba(158,172,138, 0.5);
        border-radius: 40px;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    }

    .blurb {
        display: flex;
        flex-direction: column;
        max-width: 600px;
        gap: 20px;
    }

    .text-right {
        width: 100%;
        text-align: right;
    }
}

@media screen and (max-width: $mobile-size) {
    .home {
        padding: 40px 30px;

        .logo {
            max-width: 100px;
        }

        .branch {
            height: 100px;
        }

        .first-branch {
            top: -15px;
            left: 2px;        
        }

        .second-branch {
            bottom: 2px;
            right: 2px;
        }
    }
    
}